import React from "react";
import { Container, Typography, Box } from "@mui/material";
// import HoardingImage from "../images/Hoarding.jpg";

function Home() {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage:
          'url("https://img.freepik.com/free-photo/beautiful-shot-road-half-dome-yosemite-valley-national-park-california_181624-48593.jpg?t=st=1721476437~exp=1721480037~hmac=48c785567ad26ce5c3e0814560a5777c0d0a262212e23f9533b7d5da7c2f32af&w=2000")', // Add your image URL here
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container
        sx={{
          padding: 4,
          borderRadius: 2,
          textAlign: "center",
          marginTop: "-100px",
          // background: "-webkit-linear-gradient(45deg, #FFA07A 30%, #06c 90%)",
          // WebkitBackgroundClip: "text",
          // WebkitTextFillColor: "transparent",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "#fff",
            fontFamily: 'SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: '40px',
            fontWeight: '500'
          }}
        >
          {/* Products that make your soul happy */}
        </Typography>
      </Container>
    </Box>
  );
}

export default Home;

