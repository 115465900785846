import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Products from "./components/Products";
import "./App.css";

function App() {
  return (
    <>
      <Router>
        <div className="App">
          <Header />
          {/* <Routes>
            <Route path="/" exact component={Home} />
            <Route path="/services" component={Products} />
          </Routes> */}
          <Home />
          <Products />
          <br />
          <br />
          <br />
          <br />
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
