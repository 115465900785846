import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const getResponsiveStyles = () => {
    if (isMobile) {
      return {
        fontSize: '40px',
        letterSpacing: '6px',
      };
    } else if (isTablet) {
      return {
        fontSize: '100px',
        letterSpacing: '16px',
      };
    } else {
      return {
        fontSize: '200px',
        letterSpacing: '26px',
      };
    }
  };

  return (
    <Box component="footer" sx={{ p: 2, backgroundColor: '#000', color: '#fff' }}>
      <Typography variant="body1" align="center">
        © {new Date().getFullYear()} Dainasoul. All rights reserved.
      </Typography>
      <br />
      <br />
      <Typography variant="body1" align="center">
        Contact us: 
        <br />
        +91 98134 89949
      </Typography>
      <Typography variant="body1" align="center">
        Email: contact@dainasoul.com
      </Typography>
      <br />
      <br />
      <Typography 
        variant="h1" 
        align="center" 
        style={{ flexGrow: 1, color: '#fff', ...getResponsiveStyles() }}
        fontFamily="futura"
      >
        DAINASOUL
      </Typography>
    </Box>
  );
};

export default Footer;
