import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BagSvg from "./images/BagSvg";
import MenuIconSvg from "./images/MenuIconSvg";

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const getResponsiveStyles = () => {
    if (isMobile) {
      return {
        fontSize: "24px",
      };
    } else if (isTablet) {
      return {
        fontSize: "30px",
      };
    } else {
      return {
        fontSize: "32px",
      };
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawerContent = (
    <Box sx={{ width: 500 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
        <IconButton
          onClick={toggleDrawer}
          sx={{
            backgroundColor: "black",
            color: "white",
            "&:hover": {
              backgroundColor: "black",
              transform: "scale(0.90)",
            },
            transition: "transform 0.2s",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        <ListItem button component="a" href="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component="a" href="/about">
          <ListItemText primary="About" />
        </ListItem>
        <ListItem button component="a" href="/services">
          <ListItemText primary="Services" />
        </ListItem>
        <ListItem button component="a" href="/contact">
          <ListItemText primary="Contact" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: "#fff",
        height: "80px",
        justifyContent: "center",
      }}
    >
      <Toolbar>
        <Typography
          variant="h4"
          align="center"
          sx={{
            flexGrow: 1,
            color: "#000",
            // background: "linear-gradient(45deg, #ff825b 40%, #e85cba 50%, #a546e9 55%, #7533ff 75%)",
            // WebkitBackgroundClip: "text",
            // WebkitTextFillColor: "transparent",
            fontFamily: "futura",
            letterSpacing: "0.025rem",
            ...getResponsiveStyles(),
          }}
        >
          DAINASOUL
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            variant="contained"
            sx={{
              backgroundColor: "rgb(0, 113, 227)",
              borderRadius: "50px",
              fontSize: "12px",
              height: "24px",
              padding: "11px",
              color: "white",
              "&:hover": {
                backgroundColor: "rgb(0, 113, 227)",
                color: "white",
              },
              mr: 4, // Margin-right to create space between buttons
            }}
            href="/contact"
          >
            Contact us
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="cart"
            sx={{
              mr: 2,
              mt: "4px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <BagSvg />
          </IconButton>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
            sx={{
              mr: 6,
              "&:hover": {
                backgroundColor: "transparent",
              },
              color: "black",
            }}
          >
            <MenuIconSvg />
          </IconButton>
        </Box>
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        {drawerContent}
      </Drawer>
    </AppBar>
  );
};

export default Header;
