import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';
import Pillow1 from './images/Pillow1.jpeg';
import Pillow2 from './images/Pillow2.jpeg';
import Pillow3 from './images/Pillow3.jpeg';
import Pillow4 from './images/Pillow4.jpeg';
// import Trip1 from './images/Trip1.jpeg';
// import Trip2 from './images/Trip2.jpeg';
// import Trip3 from './images/Trip3.jpeg';
// import Trip4 from './images/Trip4.jpeg';

const products = [
  {
    title: 'Pillow 1',
    description: 'Comfortable and stylish pillow.',
    image: Pillow1,
  },
  {
    title: 'Pillow 2',
    description: 'Soft and cozy pillow for your home.',
    image: Pillow2,
  },
  {
    title: 'Pillow 3',
    description: 'Elegant pillow with a modern design.',
    image: Pillow3,
  },
  {
    title: 'Pillow 4',
    description: 'Elegant pillow with a modern design.',
    image: Pillow4,
  },
];

// const products = [
//   {
//     title: 'Pangong Lake, Leh',
//     description: 'The worlds highest saltwater lake.',
//     image: Trip1,
//   },
//   {
//     title: 'Nubra Diskit Monastery, Leh',
//     description: 'The huge Maitreya Buddha statue',
//     image: Trip2,
//   },
//   {
//     title: 'Nubra Village, Ladakh',
//     description: 'Centuries-old gompas & hot sulphur springs.',
//     image: Trip3,
//   },
//   {
//     title: 'Nubra Valley Sand Dunes, Ladakh',
//     description: 'A unique amalgamation of mountains, rivers and desert',
//     image: Trip4,
//   },
// ];

const Products = () => {
  return (
    <Container sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        {products.map((product, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={product.image}
                alt={product.title}
              />
              <CardContent>
                <Typography variant="h6" component="div">
                  {product.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {product.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Products;
